import { Button } from '@/components/Button';
import Layout from '@/components/Layout';
import AkuityVsArgo from '@/static/assets/icons/SecurityHardenedArgoCd/akuity-vs-argo.svg';
import CarpetPatternIcon from '@/static/assets/icons/SecurityHardenedArgoCd/icn-carpet-pattern.svg';
import ContainerSafeIcon from '@/static/assets/icons/SecurityHardenedArgoCd/icn-container-safe.svg';
import KubeCloudIcon from '@/static/assets/icons/SecurityHardenedArgoCd/icn-kube-cloud.svg';
import KubeUpdateIcon from '@/static/assets/icons/SecurityHardenedArgoCd/icn-kube-update.svg';
import MazeIcon from '@/static/assets/icons/SecurityHardenedArgoCd/icn-maze.svg';
import SafteyShieldIcon from '@/static/assets/icons/SecurityHardenedArgoCd/icn-safety-shield.svg';
import IllSafeIcon from '@/static/assets/icons/SecurityHardenedArgoCd/ill-safe.svg';
import SecurityHardenedArgoCd from '@/static/assets/images/SecurityHardenedArgoCd/secureArgoCd.svg';
import { seoConfiguration } from '@/utils/seo-config';

import './security-hardened-argo.scss';

const { title, description, image } =
  seoConfiguration['security-hardened-argo'];

const features = [
  {
    icon: ContainerSafeIcon,
    title: 'Hardened Distroless Argo CD Containers',
    description: 'Cut down the attack surface of your application containers'
  },
  {
    icon: CarpetPatternIcon,
    title: 'SBOM and CVE Patching for Argo CD',
    description:
      'Stay ahead of vulnerabilities with regular patching and real-time security scanning'
  },
  {
    icon: SafteyShieldIcon,
    title: '100% FIPS-compliant Builds',
    description: 'Adhere to the highest standards of security compliance'
  },
  {
    icon: KubeUpdateIcon,
    title: 'Extended Kubernetes Compatibility',
    description: 'Maintaining compatibility with the last 5 Kubernetes releases'
  },
  {
    icon: MazeIcon,
    title: 'Enterprise Image Repositories',
    description:
      'Safeguard your container images with a highly available OCI repository'
  },
  {
    icon: KubeCloudIcon,
    title: 'Kubernetes CSP Compatibility',
    description:
      'Ensure compatibility with your chosen Kubernetes Cloud Service Provider'
  }
];

const SecurityHardenedArgocd = () => {
  return (
    <Layout image={image} description={description} title={title}>
      <div id="security-hardened-argo">
        <div className="header">
          <h3 className="red">Boost your business operations with</h3>
          <h1>Security-hardened Argo CD</h1>
          <h3>
            When it comes to platform engineering, every enterprise should have
            the confidence that their Argo CD setup is as secure as it can get.
            We&apos;re here to give you that assurance with our
            security-hardened Argo CD for Enterprises.
          </h3>

          <div className="header__links">
            <Button
              additionalClass="cta secondary-grey button-hover-color--secondary"
              color="akuity-purple"
              link="/talk-to-sales"
            >
              Talk to Sales
            </Button>
            <Button
              additionalClass="cta secondary-grey button-hover-color--secondary"
              color="akuity-purple"
              link="https://docs.akuity.io/security/distroless_report"
              isExternalLink
            >
              Check Documentation
            </Button>
          </div>
        </div>

        <div className="next-level-security">
          <div className="content">
            <h2>Take Your Argo CD Security to the Next Level</h2>
            <h3>
              We understand the unique needs of business owners and
              enterprise-level engineers. That’s why we offer a solution
              designed to supercharge and harden your GitOps practices with
              unmatched security features. Coming directly from the creators of
              the Argo Project.
            </h3>
          </div>
          <img src={SecurityHardenedArgoCd} alt="security hardened Argo CD" />
        </div>

        <div className="highlight-wrapper banner">
          <div className="banner__wrapper">
            <div>
              <h5>How it compares to open source?</h5>
              <p>
                Compare Akuity&apos;s Security-Hardened Argo CD images with the
                open source distribution
              </p>
            </div>
            <div className="banner__img">
              <AkuityVsArgo />
            </div>
          </div>
          <Button
            additionalClass="cta secondary-grey button-hover-color--secondary"
            color="akuity-purple"
            link="https://docs.akuity.io/security/distroless_report"
            isExternalLink
          >
            See Detailed Comparison
          </Button>
        </div>

        <div className="features">
          {features.map((feature, idx) => (
            <div key={idx} className="features__item">
              <div className="features__icon">
                <feature.icon />
              </div>
              <h5>{feature.title}</h5>
              <p>{feature.description}</p>
            </div>
          ))}
        </div>

        <div className="highlight-wrapper">
          <section>
            <div className="icon">
              <IllSafeIcon />
            </div>
            <h2>Enterprise-level Platform Engineering with Secure GitOps</h2>
            <h3>
              When it comes to security-hardened GitOps with Argo CD, Akuity’s
              help is unparalleled. We enable enterprises to build on the
              powerful base of Argo CD with security features that give you
              peace of mind and let your security team sleep tight.
            </h3>
            <h3>
              Applying for the FedRAMP authorization? Adopting a
              security-hardened Argo CD helps to expedite the FedRAMP
              authorization process!
            </h3>
          </section>
        </div>

        <div className="get-started">
          <h4 className="bold">
            Get Started with Security-hardened Argo CD for Enterprises today
          </h4>
          <br />
          <h4>
            Ensure your enterprise is built on a foundation of security and
            reliability. Let your teams embrace the confidence and strength that
            comes with using our unsurpassed GitOps solutions.
          </h4>
          <Button
            additionalClass="cta secondary-grey button-hover-color--secondary"
            color="akuity-purple"
            link="/talk-to-sales"
          >
            Talk to Sales
          </Button>
        </div>
      </div>
    </Layout>
  );
};

export default SecurityHardenedArgocd;
